import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9408 WQHD vs 8015 Full HD Sensor Sensitivity",
  "path": "/Frequently_Asked_Question/9408_vs_9008_sensor_sensitivity/",
  "dateChanged": "2022-09-26",
  "author": "Mike Polinowski",
  "excerpt": "How does the image quality of the new 2k+ WQHD line compare to the old Full HD cameras?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='IN-9408 2k+ WQHD vs IN-8015 Full HD Sensor Sensitivity' dateChanged='2022-09-28' author='Mike Polinowski' tag='INSTAR IP Camera' description='How does the image quality of the new 2k+ WQHD line compare to the old Full HD cameras?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/9408_vs_9008_sensor_sensitivity/' locationFR='/fr/Frequently_Asked_Question/9408_vs_9008_sensor_sensitivity/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    
    <h1 {...{
      "id": "in-9408-wqhd-vs-8015-full-hd-sensor-sensitivity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-9408-wqhd-vs-8015-full-hd-sensor-sensitivity",
        "aria-label": "in 9408 wqhd vs 8015 full hd sensor sensitivity permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-9408 WQHD vs 8015 Full HD Sensor Sensitivity`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#instar-full-hd-cameras"
        }}>{`INSTAR Full HD Cameras`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#instar-2k-wqhd-cameras"
        }}>{`INSTAR 2k+ WQHD Cameras`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#comparison"
        }}>{`Comparison`}</a></li>
    </ul>
    {/* /TOC */}
    <p><strong parentName="p">{`Q`}</strong>{`: How does the image quality of the new 2k+ WQHD line compare to the old Full HD cameras?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: All cameras of the INSTAR Full HD line use the `}<strong parentName="p">{`Panasonic MN34229`}</strong>{` CMOS Sensor:`}</p>
    <h2 {...{
      "id": "instar-full-hd-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-full-hd-cameras",
        "aria-label": "instar full hd cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Full HD Cameras`}</h2>
    <p><img parentName="p" {...{
        "src": "/en/86dd10a3271b81be642eae23dac5fcf6/Panasonic_MN34229_Night.webp",
        "alt": "IN-9408 2k+ WQHD vs IN-8015 Full HD Sensor Sensitivity"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "/en/8551d80c2d78c9a4aa8143d374e16a43/Panasonic_MN34229_Day.webp",
        "alt": "IN-9408 2k+ WQHD vs IN-8015 Full HD Sensor Sensitivity"
      }}></img></p>
    <h2 {...{
      "id": "instar-2k-wqhd-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-2k-wqhd-cameras",
        "aria-label": "instar 2k wqhd cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR 2k+ WQHD Cameras`}</h2>
    <p>{`The new WQHD camera line uses an Sensor by `}<strong parentName="p">{`SONY`}</strong>{` with the model number `}<strong parentName="p">{`IMX335`}</strong>{`:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/8f1bc96ee95a812b2b8d4934c5aa382d/SONY_IMX335_Night.webp",
        "alt": "IN-9408 2k+ WQHD vs IN-8015 Full HD Sensor Sensitivity"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "/en/574992af5b8c302fef9fb16a7d9f03d7/SONY_IMX335_Day.webp",
        "alt": "IN-9408 2k+ WQHD vs IN-8015 Full HD Sensor Sensitivity"
      }}></img></p>
    <h2 {...{
      "id": "comparison",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#comparison",
        "aria-label": "comparison permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Comparison`}</h2>
    <p>{`The higher resolution of the SONY sensor leads to a much higher amount of details in your camera image:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/39ebc07b6c6fe4ee6298eb17461c6a20/9408_vs_9008_01.webp",
        "alt": "IN-9408 2k+ WQHD vs IN-8015 Full HD Sensor Sensitivity"
      }}></img></p>
    <p>{`And even though the pixel count doubled from 2 million to 4 million in the WQHD sensor - which results in a much smaller area per pixel to collect light - the sensitivity increase of the `}<strong parentName="p">{`SONY STARVIS`}</strong>{` sensor leads to crisp images in extreme low light. Allowing you spot details that were hidden in sensor noise with the Full HD cameras:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/e1d0476817d66e82edb3b2f9e4a4a1d3/SONY_IMX335_Indoor.webp",
        "alt": "IN-9408 2k+ WQHD vs IN-8015 Full HD Sensor Sensitivity"
      }}></img></p>
    <p><em parentName="p">{`Indoor light camera image with WDR enabled`}</em></p>
    <p><img parentName="p" {...{
        "src": "/en/e43b16e947ee0c3f1fdcc4f62d84a283/with_wdr_01.webp",
        "alt": "IN-9408 2k+ WQHD vs IN-8015 Full HD Sensor Sensitivity"
      }}></img></p>
    <p><em parentName="p">{`Low light camera image with WDR enabled`}</em></p>
    <p><img parentName="p" {...{
        "src": "/en/99ae4eb2cb26ae507b8846f54e387da2/wo_wdr_02.webp",
        "alt": "IN-9408 2k+ WQHD vs IN-8015 Full HD Sensor Sensitivity"
      }}></img></p>
    <p><em parentName="p">{`Extreme low light camera image with WDR disabled`}</em></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      